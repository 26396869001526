// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


//what we missing in bootstrap
.m-2{
    margin: 0.5rem;
}
.mb-2{
    margin-bottom: 0.5rem;
}
.ml-2{
    margin-left: 0.5rem;
}
.mt-2{
    margin-top: 0.5rem;
}
.mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.pt-1{
    padding-left: 0.25rem;
}
.p-2{
    padding: 0.5rem;
}
.pl-2{
    padding-left: 0.5rem;
}
.pt-2{
    padding-left: 0.5rem;
}
.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}


//custom
.goout {
    transform: translateX(-100%) scale(0);
    transition: all .0s ease-in-out;
    background-color: var(--color-primary);
    min-height: 100vh;
    // width: 100%;
    // width: 60%;
    // min-width: 100vh;
     position: absolute;
     left: 0;
     top: 0;
   //  padding: 3rem 18rem 3rem 1rem;
   //  padding: 3rem 8rem;
     padding: 3rem 18rem;
}
